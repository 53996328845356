import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Optic Weather App",
  "featuredImage": "images/weather-2Bapp-2Bdesktop-2B2.png",
  "coverAlt": "Optic Weather App",
  "description": "Front end development, UI & Graphic Design, UX, and optimization of a fully featured Weather App - Optic Weather",
  "date": "2020-01-28",
  "dateModified": "2020-01-28",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Front End Development", "Graphic Design", "UI", "UX"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e4616f2b1d05426ac8617914903de2e5/42a19/weather-2Bapp-2Bdesktop-2B2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyElEQVQoz23H+08SAQDA8fshzWWptdp0+EABJUR5nng80njfHefJ4+BAk5THiQzFBB8J2uYjEeHAbPUDqWWzNbU/IJz+JoZttX7o72k43Wpr+/zw/QIquws04YTXQ8VjiaUo5SE1iNGM6819WhEE8rulXLmc16Nmd0O1/M47LPbN+oYKRkN1S7NELwKQYb/uyTBKBZ3RueXEi41YIBbx5VLTH5PhcS/RgxrFBoQtV9UJRLdbODcY9WUMRllt3V1Wk25ADngWV2D/mHVy2hyZH4nHk4nn9MuZtdXZ1YVx15AD1BtR0i7Q6Ko5bVXs1opGZiWTWc1iCRVSi70XgFzDzVr0IYwjVNA1v2CZjY/EYpHImA43PYINvRiKeimB08tDzFy9iaWFWzQwWwerbf1qrRKol0L3OsQ17UJmF6TA+1U2wuoZVsHGDpWK3Q21K5QKu1PkfCp0uIWkm+9wt9ncXItLZLYMkghQ3sgpa+SUN3ErWfxbLbwqDr9JCNZ1iGta2x/wOxskEg6kZILQfb6EASp4OCEaHBHYB8SEXePAAbGlqwMTC3FQYukS4lKJRSY2g6BVJrPLyNXegTWNklTICAgkINXQY0MAxUJ9SNCEhRHqDQakCtR6wZu8tH72T2z+HM388F+vL3HmSReoV98mkgVPsjCy8zsKZIuudNFGXxD0he0SkSmWOlMKMnVuo4sEXSTSJbZskXz93Z0q2jbOLblfQSB2hM98gqf3S2ZKkMtAovuGqT397AE8d4Bcg+cPTQtH/XOHyPRnfebUD4R3kMAmGkhjgYxplEZCWbMvpR/PWj0bOt8aEnxrCO/AE9t/eWcMbyOhnGHxCwlM7aHUltZLq/1ZjS+j9tFqX1btpdVUVjdKw6Gc4dkHePL9lfCucWLXeBlwZN8AJE6sy3nsv1bz/Sv5vpXjK8vH2PqJdeuUXMqbSvsV+wNWhiZe/K8n/wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Optic Weather app forecast",
              "title": "Optic Weather app forecast",
              "src": "/static/e4616f2b1d05426ac8617914903de2e5/42a19/weather-2Bapp-2Bdesktop-2B2.png",
              "srcSet": ["/static/e4616f2b1d05426ac8617914903de2e5/53918/weather-2Bapp-2Bdesktop-2B2.png 360w", "/static/e4616f2b1d05426ac8617914903de2e5/242a6/weather-2Bapp-2Bdesktop-2B2.png 720w", "/static/e4616f2b1d05426ac8617914903de2e5/42a19/weather-2Bapp-2Bdesktop-2B2.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Optic Weather app forecast`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e16eedd663716a11dd6f0a8943501f8a/42a19/weather-2Bapp.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC+ElEQVQoz2WS3U+ScRTHj6gID8KDL2lG9fAiD68CAhr4hiCgokxNQUOUqTOn+ZKabk2ptjZxvWovrj+gizbbyq2Wtla2lputm7pw66IuXDd14bpMkd9paF31uTnne7bzuTkHKIqCJGkAaZlZR8cKGNUNo9E0J1WoY3S+NCbOl8YE2ZJYgVQz139u4uZs9MrwzFQ/Z+nWZYhGo/AffD4/5W8rNFQ1//D3X8WpmWs4N38HK5pHUVbehfKyEMpsHairjmB9a+/23ESrEBFBZzCnCuksDiXI5AhF9KGHy8v4J6RK6kJfXH3z6Alf2js/uxhviETjhc6zcdbZF5dXRHaVVb1oc7V+RgQQ8wE4ySU+DwQCCmiaPrTQXCplqvt2GgDkqE81fC3viKK5+UJCXz9KNN4hoq7pJ4WOHiIr60zoKwKo1JV+AwA7j5tuSOdAMS8j3SSgKAstEjEHQslJplCYn/cpI0u8xaXFvzVljWgLRompcZzo60eIxjOAWs8gUdgDqLY4iLzYieypOjQ4A2jyhDCP0aJAlIMCoXgFgAtQZLEYjJU2NDnsqLWZUGevIQ3d02jw9JBj1iCRWNswz9i8n81W7xurmojeeYYYvRFS3j5J7G1jJFuiivMyc1Egyl6BlFwAo7lY39LVuhceiiS6h7sT7V2d2NTeiS3hPhy/cDE+Mj69N399EUfHp1CutaLSXE00JS5idvgJoy4mIiEdF2floIgWr3A4GQCsTqs3uCr3/JFAIjQQJuGB8LZvrG736lLs58b7TXyz/hZ3dn49XF5+fFfFKnflMoYoFdJ9ufREQiZlEgqFYpdllSiRSJ7WetwATKHCoLdbsKiyFK3VFeg+Xb6x8MGP91fHPq49f/fo2YvV12uv1nUAcESpZJFlVahSsahSJav6ICfnMpn85cFRCiTHRak8KpTCo0IAqWG5nqmJPWkbnHzgsW5934TkvyWZmbmk8Xrrgy6XJ+hyeQM+nz9Y6/UF3W5v0Ol0d7S0tPkWF+6J/wBCzAwJ63qktQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Optic weather app featured",
              "title": "Optic weather app featured",
              "src": "/static/e16eedd663716a11dd6f0a8943501f8a/42a19/weather-2Bapp.png",
              "srcSet": ["/static/e16eedd663716a11dd6f0a8943501f8a/53918/weather-2Bapp.png 360w", "/static/e16eedd663716a11dd6f0a8943501f8a/242a6/weather-2Bapp.png 720w", "/static/e16eedd663716a11dd6f0a8943501f8a/42a19/weather-2Bapp.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Optic weather app featured`}</figcaption>{`
  `}</figure></p>
    <p>{`*`}{`*`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{` PLEASE NOTE - APP IS UNDER ACTIVE DEVELOPMENT `}{`*`}{`*`}{`*`}</strong></p>
    <p>{`*`}{`*`}</p>
    <p>{`Designed and developed a fully featured weather app to track and manage the weather in multiple cities. Installable PWA, fully responsive and looks amazing on mobile and desktop. App can be upgraded to unlock more features. Polished with CSS animations and swipe gestures.`}</p>
    <p>{`Some features of the weather app include:`}</p>
    <ul>
      <li parentName="ul">{`Add multiple cities to track (auto suggestions show as you type)`}</li>
      <li parentName="ul">{`Toggle between Light, Dark and other themes`}</li>
      <li parentName="ul">{`Toggle between Celcius and Fahrenheit for temperature`}</li>
      <li parentName="ul">{`Persisting locations to local storage (until user accounts are possible).`}</li>
      <li parentName="ul">{`Toggle Full screen`}</li>
      <li parentName="ul">{`Add and remove cities`}</li>
      <li parentName="ul">{`Fully responsive`}</li>
      <li parentName="ul">{`Weather videos for different weather types`}</li>
      <li parentName="ul">{`Error handling for adding invalid locations`}</li>
      <li parentName="ul">{`Account creation to keep data between devices`}</li>
    </ul>
    <p><strong parentName="p">{`LINK TO WEB APP REMOVED UNTIL IT IS COMMERCIALLY AVAILABLE`}</strong></p>
    <p>{`Scroll down to see some screenshots.`}</p>
    <h2 {...{
      "id": "skills--technologies-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#skills--technologies-used",
        "aria-label": "skills  technologies used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills & Technologies Used`}</h2>
    <p>{`React, HTML5, SASS, Netlify, UI Design, Bootstrap, Axios, React-Player, Gatsby, GraphQL, Multiple weather API’s, CSS animations, UX, etc.`}</p>
    <h2 {...{
      "id": "mobile-screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mobile-screenshots",
        "aria-label": "mobile screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mobile Screenshots`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/weather-2Bapp-2Bmobile-2B1-139x300.png",
              "alt": "Optic Weather App Mobile Screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/weather-2Bapp-2Bmobile-2B2-139x300.png",
              "alt": "Optic Weather App Mobile Screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/weather-2Bapp-2Bmobile-2B3-138x300.png",
              "alt": "Optic Weather App Mobile Screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/weather-2Bapp-2Bmobile-2B4-138x300.png",
              "alt": "Optic Weather App Mobile Screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#",
        "aria-label": " permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <h2 {...{
      "id": "desktop-screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#desktop-screenshots",
        "aria-label": "desktop screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Desktop Screenshots`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f9d3c9a7f3d3c478ce402a8fd59b7ea3/42a19/weather-2Bapp-2Bdesktop-2B1-1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9UlEQVQozx3MeU/acACA4V90yXSbk4Hlqq3CbC2H42oBx1VEucZEOcYKcgmFAkVOBRwTxE2H7nLZssT4YReXPHn/fAGq0QkQxYIMXZAhi/DK4vLqcxh9DqMLcvSZHJ2XwI+XpI+E4tkX0IxANCsQzTxYmhVAswsioLO7EEKDEBp4XY2otIhKK8fV0jVCukZI1gixEhOiLxdhxVPp8rxYPgfJ5iDZPCR/KoGfSGGQqfO6TavabCEoiiBJgqJwE/lSb8AMRtxIYnrT6oYOUW/I8P87JS5W4pACgxSYRLkOKs36QSEfikfdoaDD77Vuuym3i9pymbfozR2P0++j3wRcwYDD79vc9phpt8npJF0unc2utW6C8edRt9/Nc8VE9iD8/l0guu+P7O0m4rGDZKqQK9Wr7V6nOzhu9zrVJs/y3GGllCsXM+xhInsAfv66OTs/rbVqXKPK1avVJn/UbQ4+9s8mw9NRf3I5+nk7/f3n6+3vm8vpOd+qsbXSIVfMlQrlOgeupheDs1530GmdND+MByfDk8ZJi+8cpYq5aDoZTSfj2VSqmGEO077ovnc/nGHzhUqRO+LqbR5k2bw94HcEgybPtt7t0Tpplc2BmiwyHblstIRTzJtE3Ozz+mORVy46lIj1h92Ly9H0+mI4HoBgLIIaKCGhhzRGkdoAaUmp3izSktAGqXdvszzH8lyhVspzRSafZvIZtlqKpJkwkyhUWGAL+FQ2B2ygUJMV0hoRk1XjpBUW226SOT3rtXrtxnHjeNCuNWuVRpU7qpTr5Ujq/R6TaPeawLP31hcJe6N7dDjki0XLjUqn38pzhXg2meMKx4PWaHI6vT6ffBpe35zf3f24v7/9+/fb9x+fr76MgRBXLREavYu2eL32QCAYj+0ycQNNC9c1AkyN6IwKE6V+baM8W6+9O+5QsFwvjScfJp+GH0d9AEQwECMz0hUgXgGS1TkUm1vBHj8Uf6YkHijWF9cIIa4RYmoRrkJ1hg273ebzmrc8/wAdONpky0kbnAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Optic Weather App Screenshot 1",
              "title": "Optic Weather App Screenshot 1",
              "src": "/static/f9d3c9a7f3d3c478ce402a8fd59b7ea3/42a19/weather-2Bapp-2Bdesktop-2B1-1.png",
              "srcSet": ["/static/f9d3c9a7f3d3c478ce402a8fd59b7ea3/53918/weather-2Bapp-2Bdesktop-2B1-1.png 360w", "/static/f9d3c9a7f3d3c478ce402a8fd59b7ea3/242a6/weather-2Bapp-2Bdesktop-2B1-1.png 720w", "/static/f9d3c9a7f3d3c478ce402a8fd59b7ea3/42a19/weather-2Bapp-2Bdesktop-2B1-1.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Optic Weather App Screenshot 1`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e4616f2b1d05426ac8617914903de2e5/42a19/weather-2Bapp-2Bdesktop-2B2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyElEQVQoz23H+08SAQDA8fshzWWptdp0+EABJUR5nng80njfHefJ4+BAk5THiQzFBB8J2uYjEeHAbPUDqWWzNbU/IJz+JoZttX7o72k43Wpr+/zw/QIquws04YTXQ8VjiaUo5SE1iNGM6819WhEE8rulXLmc16Nmd0O1/M47LPbN+oYKRkN1S7NELwKQYb/uyTBKBZ3RueXEi41YIBbx5VLTH5PhcS/RgxrFBoQtV9UJRLdbODcY9WUMRllt3V1Wk25ADngWV2D/mHVy2hyZH4nHk4nn9MuZtdXZ1YVx15AD1BtR0i7Q6Ko5bVXs1opGZiWTWc1iCRVSi70XgFzDzVr0IYwjVNA1v2CZjY/EYpHImA43PYINvRiKeimB08tDzFy9iaWFWzQwWwerbf1qrRKol0L3OsQ17UJmF6TA+1U2wuoZVsHGDpWK3Q21K5QKu1PkfCp0uIWkm+9wt9ncXItLZLYMkghQ3sgpa+SUN3ErWfxbLbwqDr9JCNZ1iGta2x/wOxskEg6kZILQfb6EASp4OCEaHBHYB8SEXePAAbGlqwMTC3FQYukS4lKJRSY2g6BVJrPLyNXegTWNklTICAgkINXQY0MAxUJ9SNCEhRHqDQakCtR6wZu8tH72T2z+HM388F+vL3HmSReoV98mkgVPsjCy8zsKZIuudNFGXxD0he0SkSmWOlMKMnVuo4sEXSTSJbZskXz93Z0q2jbOLblfQSB2hM98gqf3S2ZKkMtAovuGqT397AE8d4Bcg+cPTQtH/XOHyPRnfebUD4R3kMAmGkhjgYxplEZCWbMvpR/PWj0bOt8aEnxrCO/AE9t/eWcMbyOhnGHxCwlM7aHUltZLq/1ZjS+j9tFqX1btpdVUVjdKw6Gc4dkHePL9lfCucWLXeBlwZN8AJE6sy3nsv1bz/Sv5vpXjK8vH2PqJdeuUXMqbSvsV+wNWhiZe/K8n/wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Optic Weather App Screenshot 2",
              "title": "Optic Weather App Screenshot 2",
              "src": "/static/e4616f2b1d05426ac8617914903de2e5/42a19/weather-2Bapp-2Bdesktop-2B2.png",
              "srcSet": ["/static/e4616f2b1d05426ac8617914903de2e5/53918/weather-2Bapp-2Bdesktop-2B2.png 360w", "/static/e4616f2b1d05426ac8617914903de2e5/242a6/weather-2Bapp-2Bdesktop-2B2.png 720w", "/static/e4616f2b1d05426ac8617914903de2e5/42a19/weather-2Bapp-2Bdesktop-2B2.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Optic Weather App Screenshot 2`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4166dd98b54b935b279abb52bf16b027/42a19/weather-2Bapp-2Bdesktop-2B3.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.611111111111114%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQoz42OXU/aYBiG+xt4a1vagtCPF0uhVMpHobUUEWGiKCgqoy8FAXVqN6ciUxM1ZtGDZR5s5zvb/1zcEqMe7c599Fz3lTxY/cODjW6M7av0+rm+cY68+/HNz+u7xw10FJnbkuY2uewab3bEQo83EZd3eMORi266Okot7mDe119o/CPbusi0Jsm1M8e7r6CrZfey5oxj5ZFU6kO7K9quYLm8iXgDBfW2VEDt4WUTnWBfvv/eOn7UmueZ1kRrjlONM7V+HK0eSZVDuXIwUx7B0hAWe4LlChbijY5UdPXanl7bTVd2MMu5tp3rpcGdvjnRGmfa2om6cqzUvFj1QFrcnVkYwvkBLPbFQlewupzR0Sqj0vqRZHdFo4019h8We7cLvdv81kWqcarWPyeWP8WXPPndoVTZm1kYReaHsLgD7b5QcMU5xBmd6Vw7nO8kywPMm3wzW+Ps+mmpM9HqH1MrnlLdl8u7UmkQKfUjRVcsdKGFoOUI5nvB2A7pmzDXjOZXFWMVS+XnJdWQNVPL2dFZM52z5aQBFR0q2UgiCxNZMZ6BSkaMZ8RYWoylYslMXFFn1YQoQiwChSDLBBiaZegAS7O0P8DS/xoKstPBQJBlnssyTE4LC1yA9tNcOIzxHEdRFAAAfx0AAPk3LxEAuCozjJ/w+QBFkhjHcSRBTOH4m+IA+CnKT1E4AC+OTzJLE8D3RJ9kiiRfLp5liiTfIBwAJcoyz3I4FCII4j/f9gGgyCGG9vt8gJjC/wDD5JVqhB/jhQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Optic Weather App Screenshot 3 ",
              "title": "Optic Weather App Screenshot 3 ",
              "src": "/static/4166dd98b54b935b279abb52bf16b027/42a19/weather-2Bapp-2Bdesktop-2B3.png",
              "srcSet": ["/static/4166dd98b54b935b279abb52bf16b027/53918/weather-2Bapp-2Bdesktop-2B3.png 360w", "/static/4166dd98b54b935b279abb52bf16b027/242a6/weather-2Bapp-2Bdesktop-2B3.png 720w", "/static/4166dd98b54b935b279abb52bf16b027/42a19/weather-2Bapp-2Bdesktop-2B3.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Optic Weather App Screenshot 3 `}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c3e11223a7c060bc3f656cefba729aa3/42a19/weather-2Bapp-2Bdesktop-2B4.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.611111111111114%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJklEQVQoz03QyU/cMBQG8FwKDJPNsZM4XrLYWZxlFhiohGCmUxAHCIKi9lBaoAO0qlQOvfT/V5VMQZV++vT86b2LtYFlDIG9Yehrm6bxv4FtDmxzG1hrOgSmC2wf6g5gBdG4SEwEN01jyzK2LLNP4+X573hgm0PH2tD10ZFqV4ftanHxsDj98lbLq0qHYBtYr3vboB+A+dr0pfFGH9YH+cefp+1q/vjn08VqqSGCAfYsD5ku1KGjQzB0gA6Bjro0kLMudQg2TaM+yK+e3l8+HN88t+39QiMR8zjxGOmyhyiGxEcEuxR7LPBfeuC5k3l5/f3k4tvi5rk9v51rcZZEaZLkUqpMqrSoi6IusiqXZRpnIsqSJBedQtA4nC2bq6fl+f388vH47PZI6/ZUKpQUKk1UKqtclGlSSJ7GTEZURFSENOl4jOy+a65/nHx4Orn9fdXezTUqIpdTxAggGBBsBz6gGDLiMOLQwGEBZAFixOU0CPnusrl8XJ7dHX3+dX729VBzaAAoRpz4EQ/i0AuZFfg2wQ4jiFM3pDjmTMZURrJMy0mhpkU+zkZ7VTNTGo44ZMQNKRFRmCZqpMqxyuu8HKtmWveq8U49mlbNtJrtT2b742pcFnVeVLmGGDE818IuYiTMEqFSWWZRlvgh9UMKaeDQAMcMR504E8203N0f7ew1zURpAwjWhggO3Y7uIaNnYs/Cnum7pu9a2Ot/xHM54TKSSnIR/gVeaFvR15+XagAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Optic Weather App Screenshot 4",
              "title": "Optic Weather App Screenshot 4",
              "src": "/static/c3e11223a7c060bc3f656cefba729aa3/42a19/weather-2Bapp-2Bdesktop-2B4.png",
              "srcSet": ["/static/c3e11223a7c060bc3f656cefba729aa3/53918/weather-2Bapp-2Bdesktop-2B4.png 360w", "/static/c3e11223a7c060bc3f656cefba729aa3/242a6/weather-2Bapp-2Bdesktop-2B4.png 720w", "/static/c3e11223a7c060bc3f656cefba729aa3/42a19/weather-2Bapp-2Bdesktop-2B4.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Optic Weather App Screenshot 4`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      